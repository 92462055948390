<template>
  <a
    v-bind:href="getBarcodeUrl"
    target="_blank"
    v-bind:class="[
      'btn btn-sm btn-outline-secondary',
      !getBarcodeUrl ? 'btn-disabled disabled' : '',
    ]"
    ><i class="fas fa-print"></i> Barkod Yazdır</a
  >
</template>
<script>
import { mapState } from "vuex";
export default {
  name: "PrintBarcodeButton",
  props: {
    barcodeUrl: {
      default: null,
    },
    packageQuantity: {
      default: 1,
    },
    cargoCode: {
      default: null,
    },
    orderId: {
      default: null,
    },
  },
  computed: {
    ...mapState({
      session: (state) => state.session,
    }),
    getBarcodeUrl() {
      const apiUrl = constant.API_URL.replace("/backend", "");
      let result = null;

      let queryParams = `?domain=${window.location.host}&token=${this.session.accessToken}`;

      if (this.cargoCode && this.orderId) {
        result = `${apiUrl}cargo/barcode/${this.orderId}/${this.packageQuantity}`;
        result += queryParams;
      }

      return result;
    },
  },
};
</script>